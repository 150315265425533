<template>
  <Modal
    id="modal_confirm_deletion"
    variant="warning"
    @change="$event && hide()"
  >
    <template #title>
      {{ title }}
    </template>
    <template #subtitle>
      {{ $t("liste.attention-suppression-est-irreversible") }}
    </template>
    <template #content>
      <div
        v-if="variant === 'liste'"
        id="concerned_lists"
        class="text-medium"
      >
        <span v-html="$tc('liste.listes-concernees', toDelete.length)" />
        <strong v-if="toDelete.length === 1">{{ toDelete[0].libelle }}</strong>
        <ul
          v-else
          class="bold"
        >
          <li
            v-for="liste in toDelete"
            :key="liste.id"
          >
            {{ liste.libelle }}
          </li>
        </ul>
      </div>
      <div
        v-else-if="variant === 'panier'"
        id="concerned_lists"
        class="text-medium"
      >
        <span>{{ $t("panier.vous-etes-sur-le-point-vider-votre-panier") }}</span>
      </div>
      <p
        v-else
        class="text-regular message"
      >
        <span v-html="$tc('devis.vous-etes-sur-le-point-supprimer-produit-de-votre-devis', toDelete.length)" />
        {{ $tc("devis.une-fois-produit-suppr-plus-possible-ajouter", toDelete.length) }}
      </p>
    </template>
    <template #footer>
      <button
        class="button text-medium underline"
        @click="hide()"
      >
        {{ $t("action.retour") }}
      </button>
      <ButtonClassic
        variant="solid"
        :label="variant==='panier' ? $t('action.vider') : $t('action.supprimer')"
        color="secondary"
        icon="right"
        size="medium"
        @click="confirm()"
      >
        <template #right-icon>
          <UilTrash />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import { Modal, ButtonClassic } from "@lde/core_lde_vue";

import { UilTrash } from "@iconscout/vue-unicons";

/**
 * Modale affichée pour confirmer la suppression d'une liste.
 */
export default {
  name: "ModalConfirmDeletion",
  components: {
    Modal,
    ButtonClassic,
    UilTrash,
  },
  props: {
    /**
     * Éléments à supprimer.
     */
    toDelete: {
      type: Array,
      default: () => [],
    },
    /**
     * Variante d'affichage selon les éléments à supprimer.
     */
    variant: {
      type: String,
      validator: (value) => ["liste", "devis", "panier"].includes(value),
      required: true,
    },
  },
  emits: [
    /**
     * Déclenché lorsqu'on confirme la suppression.
     */
    "confirm",
    /**
     * Déclenché lorsqu'on refuse la suppression.
     */
    "decline",
  ],
  computed: {
    title() {
      switch (this.variant) {
        case "liste":
          return this.$t("liste.suppression-de-liste");
        case "panier":
          return this.$t("panier.vider-mon-panier");
        default:
          return this.$tc("devis.suppression-x-produit-devis", this.toDelete.length);
      }
    },
  },
  methods: {
    /**
     * Ferme la modale.
     */
    hide() {
      this.$emit("decline");
      this.$modal.hide("modal_confirm_deletion");
    },
    /**
     * Ferme la modale à la confirmation.
     */
    confirm() {
      this.$emit("confirm");
      this.hide();
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#modal_confirm_deletion {
  .modal-content { max-width: 70rem; }

  .modal-body { background-color: unset; }

  .modal-footer {
    justify-content: space-between;
    .button { color: var.$blue-light; }
  }

  #concerned_lists {
    color: var.$gray-medium;
    strong { margin-left: var.$space-xx-tiny; }
    ul { margin-left: var.$space-tiny; }
  }
}
</style>
