var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{attrs:{"id":"modal_listes_offres_indispos","variant":"warning","element-to-focus":"input"},on:{"change":function($event){!$event && _vm.hide()}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('liste.references-non-disponibles'))+" ")]},proxy:true},{key:"content",fn:function(){return [(_vm.lignesIndispos.avecRemplacement.length)?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$tc(
          'produit.les-references-suivantes-plus-dispos-remplacer-nouvelle-version',
          _vm.lignesIndispos.avecRemplacement.length
        ))}}),_c('ul',_vm._l((_vm.lignesIndispos.avecRemplacement),function(ligne){return _c('li',{key:ligne.id},[_c('span',[_vm._v(_vm._s(_vm.defineProduct(ligne).ean)+" - "+_vm._s(_vm.defineProduct(ligne).libelle))]),_c('UilArrowRight'),_c('span',[_vm._v(" "+_vm._s(ligne.offre.article.produit_remplacement.ean)+" - "+_vm._s(ligne.offre.article.produit_remplacement.libelle)+" ")])],1)}),0)]):_vm._e(),(_vm.lignesIndispos.sansRemplacement.length)?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$tc(
          'produit.les-references-suivantes-plus-dispos-doivent-etre-supprimees',
          _vm.lignesIndispos.sansRemplacement.length
        ))}}),_c('ul',_vm._l((_vm.lignesIndispos.sansRemplacement),function(ligne){return _c('li',{key:ligne.id},[_vm._v(" "+_vm._s(ligne.quantite)+"x "+_vm._s(_vm.defineProduct(ligne).ean)+" - "+_vm._s(_vm.defineProduct(ligne).libelle)+" ")])}),0)]):_vm._e(),(_vm.lignesPasDansCatalogue.length)?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$tc(
          'produit.les-references-suivantes-pas-dispos-dans-catalogue-doivent-etre-supprimees',
          _vm.lignesPasDansCatalogue.length
        ))}}),_c('ul',_vm._l((_vm.lignesPasDansCatalogue),function(ligne){return _c('li',{key:ligne.id},[_vm._v(" "+_vm._s(ligne.quantite)+"x "+_vm._s(_vm.defineProduct(ligne).ean)+" - "+_vm._s(_vm.defineProduct(ligne).libelle)+" ")])}),0)]):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('ButtonClassic',{attrs:{"variant":"solid","label":_vm.$t('general.j-ai-compris'),"color":"primary","icon":"right"},on:{"click":function($event){return _vm.hide()}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('UilThumbsUp')]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }