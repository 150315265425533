<template>
  <Modal
    id="modal_duplicate_devis_commande"
    @hidden="hide()"
  >
    <template #title>
      {{ $t("doublon-devis-commande.titre") }}
      <template v-if="nom">
        &nbsp;:&nbsp;{{ nom }}
      </template>
    </template>

    <template #content>
      <template v-if="doublonInfos.sameCommandeInfo">
        <p class="text-regular">
          {{ $t("doublon-devis-commande.commande-similaire.message") }}
        </p>
        <p class="bloc-infos">
          <span class="text-regular">
            {{ doublonInfos.sameCommandeInfo.nom }} -
            <a
              :href="`/commandes_factures/commandes/${doublonInfos.sameCommandeInfo.commande_id}`"
              target="_blank"
              rel="noreferrer noopener"
            >
              <strong>{{ $tc("commande.commande") }} N°{{ doublonInfos.sameCommandeInfo.id_4d }}</strong>
            </a>
          </span>
        </p>
        <div class="text-regular justification">
          <p>
            Indiquez ci-dessous la justification de non-doublon afin de passer à l'étape suivante:
          </p>
          <textarea
            v-model="justification"
            placeholder="Saisissez la justification..."
          />
        </div>
      </template>
      <template v-else>
        <template v-if="doublonInfos.duplicateDevisInfos && doublonInfos.duplicateDevisInfos.length !== 0">
          <p class="text-regular">
            {{ $tc("doublon-devis-commande.devis", doublonInfos.duplicateDevisInfos.length) }}
          </p>
          <div class="bloc-infos">
            <ul>
              <li
                v-for="devis in doublonInfos.duplicateDevisInfos"
                :key="devis.id"
              >
                <span class="text-regular">
                  {{ devis.ean }} "{{ devis.titre }} - {{ devis.editeur }}" ->
                  <a
                    :href="`/listes_devis/devis/${devis.devis_id}`"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <strong>{{ $tc("devis.devis") }} N°{{ devis.id_4d }}</strong>
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </template>
        <template v-if="doublonInfos.duplicateCommandeInfos && doublonInfos.duplicateCommandeInfos !== 0">
          <p class="text-regular">
            {{ $tc("doublon-devis-commande.commande", doublonInfos.duplicateCommandeInfos.length) }}
          </p>
          <div class="bloc-infos">
            <ul>
              <li
                v-for="commande in doublonInfos.duplicateCommandeInfos"
                :key="commande.id"
              >
                <span class="text-regular">
                  {{ commande.ean }} "{{ commande.titre }} - {{ commande.editeur }}" ->
                  <a
                    :href="`/commandes_factures/commandes/${commande.commande_id}`"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <strong>{{ $tc("commande.commande") }} N°{{ commande.id_4d }}</strong>
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </template>
      </template>
    </template>

    <template #footer>
      <ButtonClassic
        variant="solid"
        :label="$t('action.annuler')"
        color="primary"
        @click="hide()"
      />
      <ButtonClassic
        variant="solid"
        :label="$t('action.confirmer-pas-de-doublon')"
        color="secondary"
        @click="confirm()"
      />
    </template>
  </Modal>
</template>

<script>
import { ButtonClassic, Modal } from "@lde/core_lde_vue";

export default {
  name: "ModalDuplicateDevisCommande",
  components: {
    Modal,
    ButtonClassic,
  },
  props: {
    doublonInfos: {
      type: Object,
      required: true,
    },
    nom: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["force", "hidden"],
  data() {
    return {
      justification: "",
    };
  },
  methods: {
    confirm() {
      this.$emit("force", this.justification);
    },

    hide() {
      this.$modal.hide("modal_duplicate_devis_commande");
      this.$emit("hidden");
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#modal_duplicate_devis_commande {
  .modal-body {
    color: var.$gray-medium;
    padding: 0 var.$space-large;
  }

  .bloc-infos {
    display: flex;
    flex-direction: column;
    color: var.$blue;
    padding-left: var.$space-large;

    > :first-child {
      margin-top: var.$space-x-tiny;
      margin-bottom: var.$space-x-tiny;
    }

    &:not(:last-child) {
      margin-bottom: var.$space-small;
    }
  }

  .justification {
    textarea {
      width: 100%;
      margin-top: var.$space-x-tiny;
      padding: var.$space-x-tiny;
    }
  }

  .button-classic:first-child {
    margin-right: var.$space-tiny;
  }
}
</style>
