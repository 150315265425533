<template>
  <Modal
    id="modal_antares"
    element-to-focus="input"
    :class="{ padded: !success, }"
  >
    <template #title>
      Bestelldaten
    </template>
    <template
      v-if="!success"
      #subtitle
    >
      Angaben zur bestellenden Person. Bitte machen Sie hier Angaben zu Ihrer Person.
    </template>

    <template
      v-if="!success"
      #content
    >
      <form>
        <div class="flex-vcenter">
          <InputClassic
            v-model="data.institution"
            label="Institution"
            status="required"
          />
          <InputClassic
            v-model="data.adresszusatz"
            label="Adresszusatz"
          />
        </div>
        <div class="flex-vcenter">
          <SelectClassic
            v-model="data.anrede"
            label="Anrede"
            :options="anrede"
            status="required"
          />
          <InputClassic
            v-model="data.vorname"
            label="Vorname"
            status="required"
          />
          <InputClassic
            v-model="data.nachname"
            label="Nachname"
            status="required"
          />
        </div>
        <InputClassic
          v-model="data.strasse"
          label="Straße & Hausnummer"
          status="required"
        />
        <div class="flex-vcenter">
          <InputClassic
            v-model="data.postleitzahl"
            label="Postleitzahl"
            status="required"
          />
          <InputClassic
            v-model="data.ort"
            label="Ort"
            status="required"
          />
          <SelectClassic
            v-model="data.land"
            label="Land"
            status="required"
            :options="lands"
          />
        </div>
        <InputClassic
          v-model="data.umsatzsteuer_id"
          label="Umsatzsteuer-ID"
        />
        <div class="flex-vcenter">
          <InputClassic
            v-model="data.email"
            label="E-Mail"
            status="required"
          />
          <InputClassic
            v-model="data.email_wiederholung"
            label="E-Mail (Wiederholung)"
            status="required"
          />
        </div>
        <InputClassic
          v-model="data.telefon"
          label="Telefon (nur für eventuelle Rückfragen)"
        />
        <div class="flex-vcenter">
          <SelectClassic
            v-model="data.rechnungsempfaenger"
            label="Rechnungsempfänger:in"
            :options="[
              { value: 'rechnungsempfaenger', label: 'Ich bin der/die Rechnungsempfänger:in.', },
              // {
              //   value: 'anderer_rechnungsempfaenger',
              //   label: 'Die Rechnung soll auf eine andere Person oder Institution ausgestellt werden.'
              // }
            ]"
            status="required"
          />
        </div>
      </form>
    </template>
    <template
      v-else
      #content
    >
      <div class="text-regular">
        Ihr Bestellung ist bestätigt.<br />
        Sie werden in wenigen Augenblicken zu Ihrer Edupool Mediathek weitergeleitet.
      </div>
    </template>

    <template
      v-if="!success"
      #footer
    >
      <button
        class="button text-medium underline"
        @click="hide()"
      >
        Zurück
      </button>
      <ButtonClassic
        variant="solid"
        label="Bestellen"
        color="primary"
        icon="right"
        :disabled="disabled"
        @click="submit()"
      >
        <template #right-icon>
          <UilCheckCircle />
        </template>
      </ButtonClassic>
    </template>
    <template
      v-else
      #footer
    >
      <ButtonClassic
        variant="solid"
        label="Weiter"
        color="primary"
        icon="right"
        @click="redirect()"
      >
        <template #right-icon>
          <UilCheckCircle />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import {
  ButtonClassic, InputClassic, Modal, SelectClassic,
} from "@lde/core_lde_vue";
import { UilCheckCircle } from "@iconscout/vue-unicons";
import Api from "@/modules/axios";

/**
   * Modale affichée lors de la création d'un devis ou cmd pour antares
   */
export default {
  name: "ModalAntares",
  components: {
    Modal,
    ButtonClassic,
    InputClassic,
    SelectClassic,
    UilCheckCircle,
  },
  props: {
    commande: {
      type: Object,
      required: true,
    },
  },
  emits: [
  ],
  data() {
    return {
      success: false,
      data: {
        institution: "LDE GmbH",
        adresszusatz: "",
        anrede: { value: "Herr", label: "Herr" },
        vorname: "Ingo",
        nachname: "List",
        strasse: "Knooper Weg 107",
        postleitzahl: "24116",
        ort: "Kiel",
        land: { value: "Deutschland", label: "Deutschland" },
        umsatzsteuer_id: "",
        email: "list@musterschule.de",
        email_wiederholung: "list@musterschule.de",
        telefon: "",
        rechnungsempfaenger: { value: "rechnungsempfaenger", label: "Ich bin der/die Rechnungsempfänger:in." },
      },
      anrede: [
        { value: "Herr", label: "Herr" },
        { value: "Frau", label: "Frau" },
        { value: "Divers", label: "Divers" },
      ],
      lands: [
        { value: "Deutschland", label: "Deutschland" },
      ],
    };
  },
  computed: {
    disabled() {
      const fields = (
        this.data.institution
          && this.data.anrede
          && this.data.vorname
          && this.data.nachname
          && this.data.strasse
          && this.data.postleitzahl
          && this.data.ort
          && this.data.land
          && this.data.email
          && this.data.rechnungsempfaenger
      );
      const confirmation = this.data.email === this.data.email_wiederholung;

      return !fields || !confirmation;
    },
  },
  mounted() {
    // Hack mais Core LDE Vue ne permet pas de detecter la fermeture (TODO)
    document.querySelector("#modal_antares").addEventListener("close", () => {
      this.success = false;
    });
  },
  methods: {
    submit() {
      const data = JSON.parse(JSON.stringify(this.data));
      data.bestellung = this.commande;

      Api().post("/commande/antares/", data)
        .then((response) => {
          if (response.data.ok) {
            this.success = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hide() {
      this.$modal.hide("modal_antares");
    },
    redirect() {
      window.location.href = "https://bwdemo.edupool.cloud";
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#modal_antares {
  &.padded {
    min-width: 60%;
    .model-footer {
      padding: 0 5rem;
    }
    .modal-body {
      padding: 0 5rem;
    }
  }
  .flex-vcenter {
    gap: 1rem;
    > * {
      flex: 1;
    }
  }
  form > * {
    margin-bottom: 1rem;
  }
  .modal-footer {
    justify-content: space-between;
    .button { color: var.$blue-light; }
  }
}
</style>
