<template>
  <Modal
    id="modal_listes_offres_indispos"
    variant="warning"
    element-to-focus="input"
    @change="!$event && hide()"
  >
    <template #title>
      {{ $t('liste.references-non-disponibles') }}
    </template>
    <template #content>
      <div v-if="lignesIndispos.avecRemplacement.length">
        <p
          v-html="$tc(
            'produit.les-references-suivantes-plus-dispos-remplacer-nouvelle-version',
            lignesIndispos.avecRemplacement.length
          )"
        />
        <ul>
          <li
            v-for="ligne in lignesIndispos.avecRemplacement"
            :key="ligne.id"
          >
            <span>{{ defineProduct(ligne).ean }} - {{ defineProduct(ligne).libelle }}</span>
            <UilArrowRight />
            <span>
              {{ ligne.offre.article.produit_remplacement.ean }} -
              {{ ligne.offre.article.produit_remplacement.libelle }}
            </span>
          </li>
        </ul>
      </div>
      <div v-if="lignesIndispos.sansRemplacement.length">
        <p
          v-html="$tc(
            'produit.les-references-suivantes-plus-dispos-doivent-etre-supprimees',
            lignesIndispos.sansRemplacement.length
          )"
        />
        <ul>
          <li
            v-for="ligne in lignesIndispos.sansRemplacement"
            :key="ligne.id"
          >
            {{ ligne.quantite }}x {{ defineProduct(ligne).ean }} - {{ defineProduct(ligne).libelle }}
          </li>
        </ul>
      </div>
      <div v-if="lignesPasDansCatalogue.length">
        <p
          v-html="$tc(
            'produit.les-references-suivantes-pas-dispos-dans-catalogue-doivent-etre-supprimees',
            lignesPasDansCatalogue.length
          )"
        />
        <ul>
          <li
            v-for="ligne in lignesPasDansCatalogue"
            :key="ligne.id"
          >
            {{ ligne.quantite }}x {{ defineProduct(ligne).ean }} - {{ defineProduct(ligne).libelle }}
          </li>
        </ul>
      </div>
    </template>
    <template #footer>
      <ButtonClassic
        variant="solid"
        :label="$t('general.j-ai-compris')"
        color="primary"
        icon="right"
        @click="hide()"
      >
        <template #right-icon>
          <UilThumbsUp />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import { ButtonClassic, Modal } from "@lde/core_lde_vue";

import mixinDisponibilite from "@/mixins/mixinDisponibilite";

import { UilArrowRight, UilThumbsUp } from "@iconscout/vue-unicons";

/**
 * Modale affichée lors de la création d'une liste.
 */
export default {
  name: "ModalListesOffresIndispos",
  components: {
    Modal,
    ButtonClassic,
    UilArrowRight,
    UilThumbsUp,
  },
  mixins: [mixinDisponibilite],
  props: {
    /**
     * Offres qui ne peuvent plus être commandées.
     */
    lignesIndisponibles: {
      type: Array,
      default: () => [],
    },
    /**
     * Offres qui ne se trouvent pas dans le catalogue actif.
     */
    lignesPasDansCatalogue: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    lignesIndispos() {
      const avecRemplacement = [];
      const sansRemplacement = [];

      this.lignesIndisponibles
        .filter((ligne) => !this.lignesPasDansCatalogue.includes(ligne))
        .forEach((ligne) => {
          if (
            ligne.offre?.article?.produit_remplacement
            && this.isOfferAvailable(ligne.offre.article.produit_remplacement.offre_defaut)
          ) {
            avecRemplacement.push(ligne);
          } else {
            sansRemplacement.push(ligne);
          }
        });

      return {
        avecRemplacement,
        sansRemplacement,
      };
    },
  },
  methods: {
    /**
     * Définit le produit selon son catalogue.
     * @param {Object} ligne Infos de la lignes.
     * @returns {Object} Produit de la ligne.
     */
    defineProduct(ligne) {
      return ligne?.offre?.manuel_numerique
        || ligne?.offre?.fourniture
        || ligne?.offre?.article
        || ligne?.offre;
    },
    /**
     * Ferme la modale et remet les valeurs par défaut.
     */
    hide() {
      this.$modal.hide("modal_listes_offres_indispos");
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/modals/modal_listes_offres_indispos.scss";
</style>
